import {getProduct, getAllProducts} from "./apis/product";

export async function loader(){
    const products = [{"name":"prodotto1", "price":3}]
    return { products };
}

export async function productLoader({params}){
    const product = await getProduct(params.productId);
    console.log(product)
    return { product };
}