import {Col, Container, Row } from "react-bootstrap";
import ProductItemType from "../components/product-item-type";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

//compoments
import ProductItem from "../components/product-item";
import CustomButton from "../components/custom-button";

//images
import necklaceType from "../resources/images/la_perla_1.jpg"
import earringType from "../resources/images/pearl_hoops_1.jpg"
import ringType from "../resources/images/perla_vintage_1.jpg"
import braceletType from "../resources/images/bracelet_type.jpg"

//product labels
import Discount from "../components/imageLabels/discount";
import New from "../components/imageLabels/new";

//icons
import { LiaShippingFastSolid } from "react-icons/lia";
import { MdOutlinePayment } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { BiWorld } from "react-icons/bi";
import { MdOutlineDiscount } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getHomePageProducts } from "../apis/product";

export default function Home(){
    const [closeDiscountBox, setCloseDiscountBox] = useState(false);
    const [homePageProducts, setHomePageProducts] = useState([]);

    useEffect(() => {
        fetchHomePageProducts();
    },[]);

    return (
        //renderHomepageImage()}
        <>
            
            <div className="homepage-content">
                <Container style={{position: "relative", display: closeDiscountBox?"none":""}}>
                    {renderDiscountBox()}
                </Container>

                <h3>Acquista i nostri prodotti</h3>
                <Container>
                    {renderCarouselCategories()}
                    <hr/>
                </Container>

                <h3>Nuova Collezione</h3>
                <br></br>
                {renderProducts()}
                <br/>

                {renderHomepageIcons()}

            </div>
        </>
    );

    async function fetchHomePageProducts(){
        const products = await getHomePageProducts();
        setHomePageProducts(products);
    }

    function renderDiscountBox(){
        return (
            <>  
                <div style={{position:"absolute", right:10}}>
                    <IoMdClose size={21} color="#494949" onClick={() => setCloseDiscountBox(true)}/>
                </div>
                <Row style={{paddingBottom: "0px", marginBottom: "20px"}} className="justify-content-center discount-box-homepage">
                    <Col style={{paddingBottom: "0px"}} xs="auto">
                        <h5 className="text-discount-box-homepage"> <MdOutlineDiscount  size={30}/> Ottieni 10% sul tuo primo acquisto!</h5>
                    </Col>
                    <Col style={{paddingBottom: "0px"}} xs="auto">
                        <p className="discount-box-outline-homepage">PRIMO10</p>
                    </Col>        
                </Row>
            </>
        );
    }

    function renderHomepageIcons(){
        return (
            <Container style={{marginBottom: "20px"}}>
                <Row>
                    <Col xs={6} md={3}>
                        <LiaShippingFastSolid size={40}/>
                        <p className="homepage-icons-text">Spedizione gratuita</p>
                        <p className="homepage-icons-subtext">Oltre i €29.90</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <MdOutlinePayment size={40}/>
                        <p className="homepage-icons-text">Pagamenti sicuri</p>
                        <p className="homepage-icons-subtext">Carta e Paypal</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <BiSupport size={40}/>
                        <p className="homepage-icons-text">Supporto 24/7</p>
                        <p className="homepage-icons-subtext">Contattaci quando vuoi!</p>
                    </Col>
                    <Col xs={6} md={3}>
                        <BiWorld size={40}/>
                        <p className="homepage-icons-text">in tutta italia</p>
                        <p className="homepage-icons-subtext">Sì anche da te!</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    function renderProducts(){
        return (
            <Container>
                <Row>
                    {homePageProducts.map((product) => {
                        return (
                            <Col xs={6} md={3}>
                                <ProductItem 
                                    to={product.id} image={product.image} itemName={product.name}
                                    discount={product.discount} discountFrom={product.discountFrom}
                                    price={product.price}  stars={product.stars} available={product.available}
                                    revNumbers={product.revNumbers} labels={product.tags}/>
                            </Col>
                        )
                    })}
                </Row>
                <Row className="justify-content-center" style={{marginTop: "10px"}}>
                    <Link to="/products"><CustomButton text="Mostra Tutti"/></Link>
                </Row>
                <hr/>
            </Container>
        );
    }

    function renderHomepageImage(){
        return(
            <div className="homepage-image">
                <Container className="d-flex align-items-center">
                    <Col>
                        <Row lg={true}>
                            <h1 className="title-homepage-image">Scintille di Eleganza, Riflessi di Stile.</h1>
                        </Row>
                        <Row lg={true}>
                            <h5 className="subtitle-homepage-image">Scopri la nuova collezione invernale</h5>
                        </Row>
                        <Row>
                            <Link to="/products" style={{ textDecoration: 'none' }}><button className="button-homepage-image">Acquista subito!</button></Link>
                        </Row>
                    </Col>
                </Container>
            </div>
        );
    }

    function renderCarouselCategories(){
        return(
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={5000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                    desktop: {
                    breakpoint: {
                        max: 3000,
                        min: 1024
                    },
                    items: 4,
                    partialVisibilityGutter: 40
                    },
                    mobile: {
                    breakpoint: {
                        max: 464,
                        min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                    },
                    tablet: {
                    breakpoint: {
                        max: 1024,
                        min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                    }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={2}
                swipeable
                >
                    <Link to="/products?type=collana" style={{textDecoration: "none"}} ><ProductItemType itemName="Collane" imageUrl={necklaceType}/></Link>
                    <Link to="/products?type=anello" style={{textDecoration: "none"}} ><ProductItemType itemName="Anelli" imageUrl={ringType}/></Link>
                    <Link to="/products?type=bracciale" style={{textDecoration: "none"}} ><ProductItemType itemName="Braccialetti" imageUrl={braceletType}/></Link>
                    <Link to="/products?type=orecchini" style={{textDecoration: "none"}} ><ProductItemType itemName="Orecchini" imageUrl={earringType}/></Link>
            </Carousel>
        );
    }
}