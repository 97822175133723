import { Col, Container, Row } from "react-bootstrap";
import {useEffect, useState} from 'react';
import {useLoaderData} from "react-router-dom";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import { FaCircleInfo } from "react-icons/fa6";

export default function Product(){
    const { product } = useLoaderData();
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
          setWindowSize(getWindowSize());
        }
    
        window.addEventListener('resize', handleWindowResize);
    
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
      }, []);

    const images = buildImages();

    return (
        <Container style={{paddingTop: "50px", paddingBottom: "50px"}}>
            <Row>
                <Col xs={12} sm={5} md={6}>
                    <ImageGallery thumbnailPosition={windowSize.innerWidth<576?"bottom":"left"} items={images} />
                </Col>
                <Col xs={12} sm={7} md={6} className="d-flex flex-column justify-content-between">
                    <Row>
                        <Col>
                            {/*<Stars number={product.stars} revNumber={product.revNumber}/>*/}
                            <h3 className="product-item-text">{product.name}</h3>

                            <h5 className="product-page-price">€{product.price.toFixed(2)} {product.discount &&<span className="discount-text-card">€{product.discountFrom.toFixed(2)}</span>}</h5>
                            
                            {renderAvailable()}

                            <br/><h6 className="product-page-description-title">Isrtuzioni:</h6>
                            <h6 className="product-page-description">{product.description}</h6>

                            <br/><h6 className="product-page-description-title">Materiale:</h6>
                            <h6 className="product-page-description">{product.material.map(e => {return (<>{e}<br/></>)})} {product.color}</h6> 
              
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={12}>
                            <div className="order-box-info">
                                <Row>
                                    <Col xs={"auto"}>
                                        <FaCircleInfo color="#0096c7" size={23}/>
                                    </Col>
                                    <Col>
                                        <p style={{textAlign : "justify", fontWeight: "350"}}>Per ordinare questo prodotto contattaci sulla nostra pagina <a style={{color:"black"}} href="http://instagram.com/_u/wissbijoux_/"><strong>social</strong></a>!</p>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )

    function renderAvailable(){
        if(product.available === false)
            return(
                <>
                    <hr/>
                    <h6 style={{fontWeight: "bold", color: "red"}}>ESAURITO</h6>
                    <hr/>
                </>
            )
    }

    /*
    <Row >
                        <Col xs={"auto"}>
                            <input style={{marginBottom: "5px", width: ""}} min="1" max="5" type="number" value="1"></input><br/>
                        </Col>
                        <Col className="d-flex flex-column align-items-start">
                            
                            <button className="custom-button"><CiShoppingCart size={23}/> Aggiungi al carrello!</button>
                        </Col>
                    </Row>
    */

    function buildImages(){
        var imagesjson = [];
        
        imagesjson.push(
            {
                original: product.image,
                thumbnail: product.image,
            }
        );

        product.images.forEach((image) => {
            imagesjson.push(
                {
                    original: image,
                    thumbnail: image,
                },
            );
        });
        return imagesjson;
    }

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }
}