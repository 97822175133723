import pearl_hoops1 from "../resources/images/pearl_hoops_1.jpg"
import pearl_hoops2 from "../resources/images/pearl_hoops2.jpg"

import perla_vintage1 from "../resources/images/perla_vintage_1.jpg"

import collana_goccia1 from "../resources/images/collana_goccia_1.jpg"
import collana_goccia2 from "../resources/images/collana_goccia_2.jpg"
import collana_goccia3 from "../resources/images/collana_goccia_3.jpg"
import collana_goccia4 from "../resources/images/collana_goccia_4.jpg"
import collana_goccia5 from "../resources/images/collana_goccia_5.jpg"
import collana_goccia6 from "../resources/images/collana_goccia_6.jpg"

import la_perla1 from "../resources/images/la_perla_1.jpg"
import la_perla2 from "../resources/images/la_perla_2.jpg"
import la_perla3 from "../resources/images/la_perla_3.jpg"

import tennis_zirconi1 from "../resources/images/tennis_zirconi_1.jpg"
import tennis_zirconi2 from "../resources/images/tennis_zirconi_2.jpg"

import ovale_zircone1 from "../resources/images/ovale_zircone_1.jpg"
import ovale_zircone2 from "../resources/images/ovale_zircone_2.jpg"
import ovale_zircone3 from "../resources/images/ovale_zircone_3.jpg"

import collana_diamante1 from "../resources/images/collana_diamante_1.jpg"
import collana_diamante2 from "../resources/images/collana_diamante_2.jpg"
import collana_diamante3 from "../resources/images/collana_diamante_3.jpg"
import collana_diamante4 from "../resources/images/collana_diamante_4.jpg"
import collana_diamante5 from "../resources/images/collana_diamante_5.jpg"

import snake_zirconi1 from "../resources/images/snake_zirconi_1.jpg"
import snake_zirconi2 from "../resources/images/snake_zirconi_2.jpg"

import bracciale_black1 from "../resources/images/bracciale_black1.jpg"
import bracciale_black2 from "../resources/images/bracciale_black2.jpg"
import bracciale_black3 from "../resources/images/bracciale_black3.jpg"


import bracciale_madreperla1 from "../resources/images/bracciale_madreperla1.jpg"
import bracciale_madreperla2 from "../resources/images/bracciale_madreperla2.jpg"
import bracciale_madreperla3 from "../resources/images/bracciale_madreperla3.jpg"

import bracciale_rosa1 from "../resources/images/bracciale_rosa1.jpg"
import bracciale_rosa2 from "../resources/images/bracciale_rosa2.jpg"

import bracciali_insieme from "../resources/images/bracciali_insieme.jpg"

import orecchini_bottega1 from "../resources/images/orecchini_bottega1.jpg"
import orecchini_bottega2 from "../resources/images/orecchini_bottega2.jpg"

import orecchini_doppio_cerchio1 from "../resources/images/orecchini_doppio_cerchio1.jpg"
import orecchini_doppio_cerchio2 from "../resources/images/orecchini_doppio_cerchio2.jpg"

import orecchini_gold1 from "../resources/images/orecchini_gold1.jpg"
import orecchini_gold2 from "../resources/images/orecchini_gold2.jpg"

import orecchini_intrecciati1 from "../resources/images/orecchini_intrecciati1.jpg"
import orecchini_intrecciati2 from "../resources/images/orecchini_intrecciati2.jpg"

import orecchini_vintage1 from "../resources/images/orecchini_vintage_bottone1.jpg"
import orecchini_vintage2 from "../resources/images/orecchini_vintage_bottone2.jpg"

import twistedTrove1 from "../resources/images/TwistedTrove Ring_1.jpg"

import dazzlingDream from "../resources/images/DazzlingDream Ring1.jpg"

import pearlEssence from "../resources/images/Pearl Essence Earrings.jpg"

import regalRadiance from "../resources/images/RegalRadiance.jpg"


export async function getAllProducts(){
    return products;
}

const products = [
    {
        "id":1,
        "name": "Pearl Hoops",
        "price": 14.90,
        "image": pearl_hoops1,
        "images": [],
        "material": ["Argento 925", "Perle d’acqua dolce", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "available": true,
        "categories": ["orecchini"],
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello.",
    },
    {
        "id":2,
        "name": "Perla vintage",
        "price": 12.90,
        "image": perla_vintage1,
        "images": [],
        "material": ["Argento 925", "Perla d’acqua dolce"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["anello"],
        "available": true,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        "id":3,
        "name": "Crystal Necklace",
        "price": 20,
        "image": collana_goccia1,
        "images": [collana_goccia2, collana_goccia3, collana_goccia4, collana_goccia5, collana_goccia6],
        "material": ["Argento 925"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 24.9,
        "discount": 20,
        "tags": ["new"],
        "categories": ["collana"],
        "available": true,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        //todo da 30 sconto
        "id":4,
        "name": "Pearl Elegance",
        "price": 25.90,
        "image": la_perla1,
        "images": [la_perla2, la_perla3],
        "material": ["Argento 925", "Perle d’acqua dolce"],
        "color": "Placcato in oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 30.5,
        "discount": 15,
        "tags": ["new"],
        "categories": ["collana"],
        "available": true,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        "id":5,
        "name": "Brillance Tennis",
        "price": 11,
        "image": tennis_zirconi1,
        "images": [tennis_zirconi2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 12.9,
        "discount": 15,
        "tags": ["new"],
        "categories": ["bracciale"],
        "available": false,
        "description" : "Resistente all’acqua, non annerisce."
    },
    {
        "id":6,
        "name": "LunaShine",
        "price": 12.50,
        "image": ovale_zircone1,
        "images": [ovale_zircone2, ovale_zircone3],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 13.9,
        "discount": 10,
        "tags": ["new"],
        "categories": ["collana"],
        "available": true,
        "description" : "Resistente all’acqua, non annerisce."
    },
    {
        "id":7,
        "name": "BrillanteBloom",
        "price": 12,
        "image": collana_diamante1,
        "images": [collana_diamante2, collana_diamante3, collana_diamante4, collana_diamante5],
        "material": ["Acciaio inossidabile anallergico",  "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 12.9,
        "discount": 7,
        "tags": ["new"],
        "categories": ["collana"],
        "available": false,
        "description" : "Resistente all’acqua, non annerisce."
    },
    {
        "id":8,
        "name": "Snake con zirconi",
        "price": 12,
        "image": snake_zirconi1,
        "images": [snake_zirconi2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 12.9,
        "discount": 7,
        "tags": ["new"],
        "categories": ["collana"],
        "available": true,
        "description" : "Resistente all’acqua, non annerisce." 
    },
    {
        "id":9,
        "name": "LuckyLeaf Nero",
        "price": 13.90,
        "image": bracciale_black1,
        "images": [bracciale_black2, bracciale_black3],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 20,
        "discount": 30.5,
        "tags": ["new"],
        "categories": ["bracciale"],
        "available": true,
        "description" : "Resistente all’acqua, non annerisce. Disponibile sia in colore bianco che nero."
    },
    {
        "id":10,
        "name": "LuckyLeaf Madreperla",
        "price": 13.90,
        "image": bracciale_madreperla2,
        "images": [bracciale_madreperla1, bracciale_madreperla3, bracciali_insieme],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 20,
        "discount": 30.5,
        "tags": ["new"],
        "categories": ["bracciale"],
        "available": false,
        "description" : "Resistente all’acqua, non annerisce."
    },
    {
        "id":16,
        "name": "LuckyLeaf Rosa",
        "price": 13.90,
        "image": bracciale_rosa1,
        "images": [bracciale_rosa2, bracciali_insieme],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 20,
        "discount": 30.5,
        "tags": ["new"],
        "categories": ["bracciale"],
        "available": false,
        "description" : "Resistente all’acqua, non annerisce."
    },
    {
        "id":11,
        "name": "AuroraHoops",
        "price": 11.50,
        "image": orecchini_vintage1,
        "images": [orecchini_vintage2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 12.5,
        "discount": 8,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": false,
        "description" : "Resistenti all’acqua, non anneriscono."
    },
    {
        "id":12,
        "name": "TwistedHoops",
        "price": 12.50,
        "image": orecchini_intrecciati1,
        "images": [orecchini_intrecciati2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "Placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": false,
        "description" : "Resistenti all’acqua, non anneriscono."
    },
    {
        "id":13,
        "name": "Bottega Gold",
        "price": 12.50,
        "image": orecchini_gold1,
        "images": [orecchini_gold2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "placcato oro",
        "stars": 0,
        "revNumber": 0,
        "discountFrom": 13.5,
        "discount": 7,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": false,
        "description" : "Resistenti all’acqua, non anneriscono."
    },
    {
        "id":14,
        "name": "DoubleHoops",
        "price": 11.90,
        "image": orecchini_doppio_cerchio1,
        "images": [orecchini_doppio_cerchio2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": true,
        "description" : "Resistenti all’acqua, non anneriscono."
    },
    {
        "id":15,
        "name": "Bottega Silver",
        "price": 12.50,
        "image": orecchini_bottega1,
        "images": [orecchini_bottega2],
        "material": ["Acciaio inossidabile anallergico", "Nichel free"],
        "color": "",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": false,
        "description" : "Resistenti all’acqua, non anneriscono."
    },
    {
        "id":16,
        "name": "TwistedTrove",
        "price": 12.90,
        "image": twistedTrove1,
        "images": [],
        "material": ["Argento 925"],
        "color": "placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["anello"],
        "available": true,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        "id":17,
        "name": "DazzlingDream",
        "price": 14.90,
        "image": dazzlingDream,
        "images": [],
        "material": ["Argento 925", "Placcato oro"],
        "color": "Disponibile anche in colorazione Silver",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["anello"],
        "available": true,
        "description" : "Regolabile. Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        "id":18,
        "name": "Pearl Essence",
        "price": 13.90,
        "image": pearlEssence,
        "images": [],
        "material": ["Argento 925"],
        "color": "placcato oro",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["orecchini"],
        "available": true,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    },
    {
        "id":19,
        "name": "RegalRadiance",
        "price": 15,
        "image": regalRadiance,
        "images": [],
        "material": ["Argento 925", "Placcato oro"],
        "color": "Disponibile anche in colorazione Silver",
        "stars": 0,
        "revNumber": 0,
        "tags": ["new"],
        "categories": ["anello"],
        "available": true,
        "description" : "Regolabile. Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    }

    /*
    {
        "id":9,
        "name": "Orecchìni Silver pendenti ",
        "price": 20,
        "image": pearl_hoops1,
        "images": [pearl_hoops2, pearl_hoops3],
        "material": "Argento 925 ",
        "color": "Argento ",
        "stars": 0,
        "revNumber": 0,
        "description" : "Conserva il tuo gioiello in un luogo asciutto lontano dall’umidità lasciandoli chiusi nella loro scatola. Fare attenzione a sostanze aggressive e corrosive che possono danneggiare il tuo gioiello."
    }*/
];