import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import RootElement from './routes/root-element';
import ErrorPage from './routes/error-page';
import Home from './routes/home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cart from './routes/cart';
import Products from './routes/products';
import Product from './routes/product';
import {productLoader} from "./loaders";

const router =  createBrowserRouter([
  {
    path: "/",
    element: <RootElement />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home/>
      },
      {
        path: "/cart",
        element: <Cart/>
      },
      {
        path:"/products",
        element: <Products/>
      },
      {
        path:"/product/:productId",
        element: <Product/>,
        loader: productLoader
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
