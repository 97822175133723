import { getAllProducts as getAllProductsDatabase } from "../database/products"

export async function getProduct(id){
    const products = await getAllProducts();
    console.log(products)
    console.log(id)
    let result = null;

    products.forEach((product) => {
        const productId = product.id;
        if(productId == id){
            result = product
        }
    })
    
    return result;
}

export async function getAllProducts(){
    return await getAllProductsDatabase();
}

export async function getHomePageProducts(){
    return (await getAllProducts()).slice(0, 4);
}