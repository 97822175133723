import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from "./logo";
import { FaCartShopping } from "react-icons/fa6";
import { useState } from "react";

export default function CustomNavbar() {
    const [isExpanded, setIsExpanded] = useState(false);

    var expand = "md"
    return (
      <>
        <Navbar fixed="top" key={expand} expand={expand} expanded={isExpanded} className="bg-body-tertiary mb-3">
          <Container fluid>
            <Navbar.Brand className="order-md-0 mx-auto order-1 flex-fill"><Logo /></Navbar.Brand>
            <Navbar.Toggle onClick={() => setIsExpanded(!isExpanded)} aria-controls={`offcanvasNavbar-expand-${expand}`} className="order-md-1 order-0"/>
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton onClick={() => setIsExpanded(false)}>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  <Logo />
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link><Link to="/" onClick={() => setIsExpanded(false)} className="navbar-item ">HOME</Link></Nav.Link>
                  <Nav.Link><Link to="/products" onClick={() => setIsExpanded(false)} className="navbar-item ">CERCA</Link></Nav.Link>
                  <NavDropdown
                    title="PRODOTTI"
                    className="navbar-item"
                    id={`offcanvasNavbarDropdown-expand-${expand}`}
                  >
                    <NavDropdown.Item ><Link onClick={() => setIsExpanded(false)} style={{textDecoration: "none", color: "black"}} to="products?type=collana">Collane</Link></NavDropdown.Item>
                    <NavDropdown.Item ><Link onClick={() => setIsExpanded(false)} style={{textDecoration: "none", color: "black"}} to="products?type=bracciale">Bracciali</Link></NavDropdown.Item>
                    <NavDropdown.Item ><Link onClick={() => setIsExpanded(false)} style={{textDecoration: "none", color: "black"}} to="products?type=anello">Anelli</Link></NavDropdown.Item>
                    <NavDropdown.Item ><Link onClick={() => setIsExpanded(false)} style={{textDecoration: "none", color: "black"}} to="products?type=orecchini">Orecchini</Link></NavDropdown.Item>
                  </NavDropdown>
                </Nav>
                
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            {/* <Nav  className="order-md-0 mx-auto order-2 justify-content-end"><Link to="/cart" className="cart-logo-link"><FaCartShopping /></Link></Nav>*/}
          </Container>
        </Navbar>
      </>
    );
  }

