import React from "react"
import { Col, Container, Row } from "react-bootstrap";
import { FaFacebookF, FaInstagram, FaYoutube, FaTiktok  } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import Logo from "./logo";

export default function Footer() {
    return (
        <Container fluid className="footer-container">
            <Row>
                <Col md={6} sm={12}>
                    <h3 className="footer-about-us-title align-items-center">
                    SU DI NOI:
                    </h3>
                    <p className="footer-about-us-text">
                    Benvenuti in Wissbijoux, crediamo che ogni gioiello racconti una storia, 
                    e vogliamo essere parte della tua. Ogni pezzo nel nostro catalogo è stato
                    scelto con cura per garantire che rifletta la bellezza intrinseca e l'artigianato superiore.
                    </p>
                </Col>
                <Col md={6} sm={12}>
                    <h3 className="footer-contacts-title">
                    CONTATTI:
                    </h3>
                    <Row className="justify-content-center">
                        <Col xs="auto" md="auto" className="justify-content-end">
                            <MdEmail /> :
                        </Col>
                        <Col xs="auto" md="auto" className="justify-content-start">
                            <a href="mailto:Oessiouri@gmail.com">Oessiouri@gmail.com</a>
                        </Col>
                    </Row>
                    
                </Col>
            </Row>
            <hr className="w-100 pb-0"/>
            <Row className="footer-header">
                <Col sm={12} md={4} className="footer-header-item footer-text">
                    Tieniti aggiornato sui nostri socials:
                </Col>
                <Col sm={12} md={4} className="footer-icons footer-header-item">                  
                    {/*<FaFacebookF className="footer-icon"/>*/}
                    <a style={{textDecoration: "inherit", color:"inherit"}} href="http://instagram.com/_u/wissbijoux_/"><FaInstagram className="footer-icon"/></a>
                    {/*<FaYoutube className="footer-icon"/>*/}
                    <a style={{textDecoration: "inherit", color:"inherit"}} href="https://www.tiktok.com//@wissbijoux/"><FaTiktok className="footer-icon"/></a>
                </Col>
                <Col sm={12} md={4} className="footer-header-item">
                    <Logo/>
                </Col>
            </Row>
        </Container>
    );
}