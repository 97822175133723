import { Col, Row } from "react-bootstrap";
import logo from "../resources/images/logo.png"
import logoWriting from "../resources/images/logo-scritta.png"
import { Link } from "react-router-dom";

export default function Logo(){
    return (
        <>
            <Row>
                <Col><Link style={{textDecoration: 'none', color: "black"}} to="/"><p className="logo-name"><img src={logo} className="logo" alt="logo" /><img alt="logo writing" className="logo-writing" src={logoWriting}></img></p></Link></Col>
            </Row>
        </>
    );
}