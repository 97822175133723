import Form from 'react-bootstrap/Form';
import {Col, Container, Row } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { FaSort } from "react-icons/fa";
import { FaRing } from "react-icons/fa6";
import ReactLoading from 'react-loading';

//compoments
import ProductItem from "../components/product-item";


//product labels
import New from "../components/imageLabels/new";
import { useEffect, useState } from 'react';
import { getAllProducts } from '../apis/product';
import { useSearchParams } from 'react-router-dom';

import notFoundImage from "../resources/images/notfound.png"


export default function Products(){
    const [products, setProducts] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const [originalProducts, setOriginalProducts] = useState([]);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [productType, setProductType] = useState(searchParams.get("type"));
    const [productOrder, setProductOrder] = useState(""); //crescente, decrescente, popolarita, novita

    if(searchParams.get("type")!==productType){
        setProductType(searchParams.get("type"));
    }

    useEffect(() => {
        fetchProducts();
    }, []);

    useEffect(() => {
        filterProducts();
    }, [originalProducts]);

    useEffect(() => {
        filterProducts();
    }, [searchString, productType, productOrder]);

    async function filterProducts(){
        let newProducts = [];
        setLoadingProducts(true);
        originalProducts.forEach((product) => {
            if(product.name.toLowerCase().includes(searchString.toLowerCase())){
                if(productType==="" || productType===null){
                    newProducts.push(product)
                } else if(product.categories.includes(productType)) {
                    newProducts.push(product)
                }
            }   
        });

        if(productOrder === "crescente"){
            newProducts = sortByPriceInc(newProducts);
        } else if(productOrder === "decrescente"){
            newProducts = sortByPriceDec(newProducts);
        }else if(productOrder === "novita"){

        }

        setProducts(newProducts);
        setLoadingProducts(false);
    }

    function sortByPriceInc(prod){
        let newProducts = [...prod];
        newProducts.sort(function(a, b) {
            return a.price - b.price;
        });
        return newProducts;
    }

    function sortByPriceDec(prod){
        let newProducts = [...prod];
        newProducts.sort(function(a, b) {
            return b.price - a.price;
        });
        return newProducts;
    }

    return (
        <>
            {renderSearchBar()}
            <h5 style={{textAlign: "center", marginTop: "50px", color: "#989898", marginBottom: "40px"}}>I TUOI RISULTATI</h5>
            {loadingProducts?renderLoading():renderProducts()}
            
        </>
    )

    function renderLoading(){
        return (
            <Row className={"justify-content-center"}>
                <ReactLoading type={"cylon"} color={"black"} height={150} width={150}></ReactLoading>
            </Row>
        )
    }

    async function fetchProducts(){
        setLoadingProducts(true);
        const fetchedProducts = await getAllProducts();
        setProducts(fetchedProducts)
        setOriginalProducts(fetchedProducts);
        setLoadingProducts(false);
    }

    function renderSearchBar(){
        return(
            <div className="search-image">
            <Container>
                <Row className="justify-content-center " style={{marginTop: "70px"}}>
                    <Col className='search-bar-container-background'>
                        <div className="search-bar-container">
                            <Col>
                            <Row>
                                <Col xs={12} sm={6} className="search-bar-column">
                                    <Row style={{margin: "10px 5px 10px 5px"}}>
                                        <Col xs={"auto"} style={{padding: "0px 5px 0px 5px"}}>
                                            <CiSearch/>
                                        </Col>
                                        <Col style={{padding: "0px"}}>
                                            <input className="search-bar-input" value={searchString} onChange={(e)=> setSearchString(e.target.value)} placeholder="Cerca..."></input>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={2} className="search-bar-column">
                                    <Row style={{margin: "10px 5px 10px 5px"}}>
                                        <Col xs={"auto"} style={{padding: "0px 5px 0px 5px"}}>
                                            <FaRing/>
                                        </Col>
                                        <Col style={{padding: "0px"}}>
                                            <select className="select-without-icon" defaultValue={productType} value={productType} onChange={(choice) => {setProductType(choice.target.value); setSearchParams({"type": choice.target.value})}} style={{padding: "0px", border: "0px"}} aria-label="type">
                                                <option></option>
                                                <option value="anello">Anelli</option>
                                                <option value="collana">Collane</option>
                                                <option value="bracciale">Bracciali</option>
                                                <option value="orecchini">Orecchini</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={2}>
                                    <Row style={{margin: "10px 5px 10px 5px"}}>
                                        <Col xs={"auto"} style={{padding: "0px 5px 0px 5px"}}>
                                            <FaSort/>
                                        </Col>
                                        <Col style={{padding: "0px"}}>
                                            <select onChange={(choice) => {setProductOrder(choice.target.value)}} style={{padding: "0px", border: "0px"}} aria-label="type">
                                                <option></option>
                                                <option value="crescente">Per prezzo ↑</option>
                                                <option value="decrescente">Per prezzo ↓</option>
                                                <option value="novita">Novità</option>
                                            </select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={4} sm={2} style={{padding: "0px 12px"}}>
                                    <button className="custom-button-search">Cerca</button>
                                </Col>
                            </Row>
                            </Col>

                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
        );
    }

    function renderSpacer(){
        return(
            <div style={{height: "50px"}}></div>
        );
    }

    function renderProducts(){
        return (
            <Container>
                <Row className="">
                    {products.length?products.map((product, i) => {

                        return (
                            <Col xs={6} md={3}>
                                <ProductItem image={product.image} itemName={product.name}
                                price={product.price} to={product.id} stars={product.stars}
                                revNumbers={product.revNumbers} labels={product.tags} available={product.available}
                                discount={product.discount} discountFrom={product.discountFrom}/>
                            </Col>
                        )
                    }):
                        <>  
                            <div style={{width: "100%", textAlign: "center"}}>
                                <img style={{margin: 0, height: "250px", width: "auto"}} alt="not found" src={notFoundImage}/>
                            </div>
                            <h3 style={{textAlign: "center", color: "#464646"}}>Nessun prodotto trovato!</h3>
                            {renderSpacer()}
                        </>}
                </Row>
            </Container>
        );
    }
}