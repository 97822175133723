import { Link } from "react-router-dom";
import Stars from "./stars";
import { useSpring, animated } from "react-spring";
import New from "./imageLabels/new"
import Discount from "./imageLabels/discount";
import OutOfStock from "./imageLabels/out-of-stock"

export default function ProductItem({image, itemName, price, to, stars, revNumbers, labels, discount, discountFrom, available}){
    const props = useSpring({
        to: {opacity: 1},
        from: {opacity: 0},
        config: {
            duration: 400
        }
    })

    return (
        <Link to={{ pathname: "/product/" + to }} style={{textDecoration: 'none', color: "black"}}>
            <animated.div style={props}>
                <div className={"card-container "} style={{width: "100%", display: "flex", justifyContent: "center", marginBottom: "25px", padding: "5px", borderRadius: "5px"}}>
                <div style={{zIndex: "999"}} className="label-container-image">{renderLabel()}</div>   
                <div style={{maxWidth: "200px"}} className={available===false&&"grey-image"}>
                    <div class="image-holder">
                        <img src={image} alt={itemName} className={"image-product-item"}/>
                    </div>
                    <p style={{color: "#494949", textAlign: "center", fontWeight: "500", marginBottom: "12px", marginTop: "5px"}}>{itemName}</p>
                    <h5 style={{textAlign: "left", zIndex: "999"}}>€{price.toFixed(2)} EUR {discount?<span className="discount-text-card">€{discountFrom.toFixed(2)}</span>:""}</h5>
                    {/*<Stars number={stars} revNumber={revNumbers}/>*/}
                </div>
                </div>
            </animated.div>
        </Link>
    );

    function renderLabel(){
        if(available===false){
            return (
                <OutOfStock></OutOfStock>
            )
        }

        if(labels === undefined) return;

        return(
            <>
                {discount && <Discount amount={discount}/>}
                {labels.map((label) => {
                    return (fetchLabel(label))
                })}
            </>
        );
    }

    function fetchLabel(labelName){

        if(labelName==="new"){
            return <New/>
        }

    }
}