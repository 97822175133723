import { Outlet } from "react-router-dom";
import CustomNavbar from "../components/navbar";
import Footer from "../components/footer";
import ScrollToTop from "../components/scroll-to-top";

export default function RootElement() {

    return (
      <>
        <CustomNavbar />
        <ScrollToTop/>
        <Outlet />
        <Footer/>
      </>
    );
  }

